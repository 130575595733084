<!--
 * @Autor: Haibo Deng
 * @Date: 2022-03-25 23:06:57
 * @Description:
 * @LastEditTime: 2022-06-14 14:53:15
 * @LastEditors: Haibo Deng
-->
<template>
  <div class="container">
    <div class="header_wrap">
      <div class="header">
        <div class="logo">
          <img src="../assets/logo.png" alt="">
        </div>
        <router-link to="/home">首页</router-link>
        <router-link :to="{path:'/detail',query:{cid:0,type:4,title:'型号查询'}}">产品中心</router-link>
        <router-link to="/contact">联系我们</router-link>
      </div>
    </div>
    <div class="layout">
      <router-view />
    </div>
  </div>
</template>
<script>

export default {
  name: "Layout",
  components: {

  },
}
</script>
<style lang="less" scoped>
.container {
  .layout{
    padding-top: 78px;
  }
  width: 100%;
  overflow: hidden;
  min-height:100vh;
  .header_wrap{
    a{
      color: #8E8E8E;
      margin-left: 52px;
      text-decoration: none!important;
      &.router-link-active{
        color: #333333;
      }
    }
    position: fixed;
    left: 0;
    top: 0;
    z-index: 17;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(223, 215, 215, 0.5);
    height: auto;
    .header{
      display: flex;
      height: 78px;
      align-items: center;
      .logo{
        justify-content: center;
        width: 160px;
        height: auto;
        img{
          display: block;
          width: 100%;
        }
      }
      width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
